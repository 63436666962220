import React from 'react';
import { Grid, Tooltip, Box } from '@material-ui/core';
import {
  InfoCard,
  Progress,
  ResponseErrorPanel,
  GaugeCard,
} from '@backstage/core-components';
import { useSecureScores } from './useSecureScores';
import { Typography } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import { Score } from '@internal/plugin-defender-for-cloud-scores-common';
import { useThresholds } from './useThresholds';
import { useOwnedEntities } from '@backstage-community/plugin-manage-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { fetchCurrentScores } from '../api/secureScoresApi';
import { catalogApiRef, useStarredEntities } from '@backstage/plugin-catalog-react';

const DEEPLINK_SECURITY_TAB = {
  title: 'See all scores',
  link: 'defender-for-cloud-scores',
};

// Component for showing when no scores need attention
const HealthyScoresCard = ({ scoresCount, showLink = true }: { scoresCount: number; showLink?: boolean }) => (
  <Box width={250} height={'100%'}>
    <InfoCard
      title="Looking good!!"
      subheader={`All ${scoresCount} subscription have healthy secure scores`}
      deepLink={showLink ? DEEPLINK_SECURITY_TAB : undefined}
      variant="gridItem"
    >
      <Box
        display="flex"
        justifyContent="center"
        position={'relative'}
        lineHeight={0}
      >
        <CheckCircleOutline
          color="primary"
          style={{
            width: '80%',
            height: 'unset',
          }}
        />
      </Box>
    </InfoCard>
  </Box>
);

// Component for showing when no data is available
const NoDataCard = () => (
  <Box width={250} height={'100%'}>
    <InfoCard
      title="No data available"
      deepLink={DEEPLINK_SECURITY_TAB}
      variant="gridItem"
    >
      <Typography>No secure scores were found for this team.</Typography>
    </InfoCard>
  </Box>
);

const AverageScoreCard = ({
  averageScore,
  showLink = true,
}: {
  averageScore?: number | null;
  showLink?: boolean;
}) => {
  const { getGaugeColor } = useThresholds();
  if (!averageScore) return null;
  return (
    <Grid item>
      <GaugeCard
        progress={averageScore}
        variant="gridItem"
        title="Secure Score Avg"
        icon={
          <Tooltip
            title="This score is calculated based on all subscriptions weights and scores"
            arrow
          >
            <Info
              style={{
                position: 'relative',
                float: 'right',
              }}
            />
          </Tooltip>
        }
        getColor={getGaugeColor}
        deepLink={showLink ? DEEPLINK_SECURITY_TAB : undefined}
      />
    </Grid>
  );
};

const NeedsAttentionCard = ({ scores, showLink = true }: { scores: Score[]; showLink?: boolean }) => {
  const { getGaugeColor, thresholds } = useThresholds();

  const scoresNeedingAttention = scores
    .filter(score => score.percentageScore < thresholds.target)
    .sort((a, b) => a.percentageScore - b.percentageScore);

  return (
    <Grid item>
      {scoresNeedingAttention.length > 0 ? (
        <GaugeCard
          progress={scoresNeedingAttention[0].percentageScore}
          variant="gridItem"
          size="normal"
          title="Lower Score"
          subheader={`${scoresNeedingAttention.length}/${scores.length} subscriptions needs attention`}
          getColor={getGaugeColor}
          deepLink={showLink ? DEEPLINK_SECURITY_TAB : undefined}
        />
      ) : (
        <HealthyScoresCard scoresCount={scores.length} showLink={showLink} />
      )}
    </Grid>
  );
};

export const SecureScoreOverviewCards = () => {
  const { value, loading, error } = useSecureScores();

  if (loading) return <Progress />;
  if (error) return <ResponseErrorPanel error={error} />;
  if (!value || value.currentScores.length === 0) return <NoDataCard />;

  return (
    <Grid container spacing={1}>
      <AverageScoreCard averageScore={value.averageScore} />
      <NeedsAttentionCard scores={value.currentScores} />
    </Grid>
  );
};

export const SecureScoreManageCards = ({ showLink = false }: { showLink?: boolean }) => {
  const ownedEntities = useOwnedEntities('resource');
  const discoveryApi = useApi(discoveryApiRef);
  const fetch = useApi(fetchApiRef);
  const catalogApi = useApi(catalogApiRef);

  const subscriptions = ownedEntities
    .filter(entity => entity.spec?.type === 'azure-subscription')
    .map(entity => stringifyEntityRef(entity));

  const { value, loading, error } = useAsync(async () => {
    if (subscriptions.length === 0) {
      return { currentScores: [], averageScore: null };
    }
    return fetchCurrentScores(subscriptions, {
      fetch,
      discoveryApi,
      catalogApi,
    });
  }, [subscriptions.join(',')]);

  if (loading) return <Progress />;
  if (error) return <ResponseErrorPanel error={error} />;
  if (!value || value.currentScores.length === 0) return <NoDataCard />;

  return (
    <Grid container spacing={1}>
      <AverageScoreCard averageScore={value.averageScore} showLink={showLink} />
      <NeedsAttentionCard scores={value.currentScores} showLink={showLink} />
    </Grid>
  );
};

// New component specifically for starred subscriptions
export const StarredSecureScoreCards = ({ showLink = false }: { showLink?: boolean }) => {
  const { starredEntities } = useStarredEntities();
  const discoveryApi = useApi(discoveryApiRef);
  const fetch = useApi(fetchApiRef);
  const catalogApi = useApi(catalogApiRef);

  const subscriptions = [...starredEntities].filter(ref => ref.startsWith('resource:'));

  const { value, loading, error } = useAsync(async () => {
    if (subscriptions.length === 0) {
      return { currentScores: [], averageScore: null };
    }
    return fetchCurrentScores(subscriptions, {
      fetch,
      discoveryApi,
      catalogApi,
    });
  }, [subscriptions.join(',')]);

  if (loading) return <Progress />;
  if (error) return <ResponseErrorPanel error={error} />;
  if (!value || value.currentScores.length === 0) return <NoDataCard />;

  return (
    <Grid container spacing={1}>
      <AverageScoreCard averageScore={value.averageScore} showLink={showLink} />
      <NeedsAttentionCard scores={value.currentScores} showLink={showLink} />
    </Grid>
  );
};
