/***/
/**
 * Common functionalities for the defender-for-cloud-scores plugin.
 *
 * @packageDocumentation
 */

import { z } from 'zod';

export const scoreSchema = z.object({
  entityRef: z.string(),
  entityTitle: z.string(),
  displayName: z.string(),
  maxScore: z.number(),
  currentScore: z.number(),
  percentageScore: z.number(),
  weight: z.number(),
  date: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
});

export const scoreByRefRequestBodySchema = z.object({
  entityRefs: z.array(z.string()),
});

export const scoreByRefResponseSchema = z.object({
  currentScores: z.array(scoreSchema),
  averageScore: z.number(),
});

export const errorResponseSchema = z.object({
  error: z.string(),
});

/**
 * TypeScript types inferred from Zod schemas
 */
export type Score = z.infer<typeof scoreSchema>;
export type ScoreByRefRequestBody = z.infer<typeof scoreByRefRequestBodySchema>;
export type ScoreByRefResponse = z.infer<typeof scoreByRefResponseSchema>;
export type ErrorResponse = z.infer<typeof errorResponseSchema>;
