import { useTheme } from '@material-ui/core';

import { Score } from '../../types';
import { ChartData, ChartOptions } from 'chart.js';
import { useThresholds } from '../useThresholds';

export const useChartData = (): ((scoreArray: Score[]) => ChartData<
  'line',
  number[]
> & {
  options: ChartOptions<'line'>;
  entityTitle?: string;
}) => {
  const theme = useTheme();
  const { thresholds } = useThresholds();

  return function chartDataMapper(scoreArray) {
    const latestScore = scoreArray[scoreArray.length - 1];

    return {
      labels: scoreArray.map(
        score => new Date(score.date).toISOString().split('T')[0],
      ),
      datasets: [
        {
          label: `Defender for Cloud Score (Current ${latestScore.percentageScore * 100}%)`,
          data: scoreArray.map(score => score.percentageScore * 100),
          fill: false,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          borderWidth: 3.5,
          pointStyle: 'rect',
        },
        {
          label: `KPI (${thresholds.target * 100}%)`,
          data: scoreArray.map(() => thresholds.target * 100),
          fill: false,
          borderColor: theme.palette.status.error,
          borderWidth: 1.5,
          borderDash: [10, 5],
          pointStyle: 'line',
        },
      ],
      options: {
        scales: {
          x: {
            ticks: { color: theme.palette.text.primary },
            grid: { color: theme.palette.divider },
          },
          y: {
            beginAtZero: true,
            max: 100,
            ticks: { color: theme.palette.text.primary },
            grid: { color: theme.palette.divider },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: theme.palette.text.primary,
              usePointStyle: true,
            },
          },
          tooltip: {
            titleColor: theme.palette.text.primary,
            bodyColor: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
          },
        },
      },
      entityTitle: latestScore?.entityTitle,
    };
  };
};
