import React from 'react';
import {
  Page,
  InfoCard,
  Progress,
  ResponseErrorPanel,
  Content,
  ContentHeader,
} from '@backstage/core-components';
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSecureScoresHistory } from './useSecureScores';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { useChartData } from './helpers/chartConfig';
import { useOwnedEntities } from '@backstage-community/plugin-manage-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { fetchHistoricalScores } from '../api/secureScoresApi';
import useAsync from 'react-use/esm/useAsync';
import { resourceCards } from './resources/resourceCards';
import { Link } from 'react-router-dom';
import { Button, CardActions } from '@material-ui/core';
import { Score } from '@internal/plugin-defender-for-cloud-scores-common';

ChartJS.register(
  LineElement,
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  Filler,
);
interface SecurityPageProps {
  scores?: Score[][] | undefined;
}

export const SecurityPage = ({ scores }: SecurityPageProps) => {
  const theme = useTheme();
  const chartDataMapper = useChartData();

  const chartData = scores?.map(chartDataMapper);

  return (
    <Page themeId="tool">
      <Content>
        <Grid container spacing={3}>
          {resourceCards.map(card => (
            <Grid item xs={12} md={6} key={card.title}>
              <InfoCard title={card.title}>
                <Typography>{card.description}</Typography>
                <CardActions style={{ justifyContent: 'flex-end' }}>
                  <Button
                    size="small"
                    color="primary"
                    component={Link}
                    to={card.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {card.linkTitle}
                  </Button>
                </CardActions>
              </InfoCard>
            </Grid>
          ))}
        </Grid>
        <div style={{ marginTop: theme.spacing(3) }}>
          <ContentHeader title="Security Historical Scores" />
        </div>
        {scores && scores.length !== 0 ? (
          <Grid container spacing={3}>
            {chartData &&
              chartData.map((data, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <InfoCard title={`Subscription: ${data.entityTitle}`}>
                    <div style={{ width: '100%' }}>
                      <Line data={data} options={data.options} />
                    </div>
                  </InfoCard>
                </Grid>
              ))}
          </Grid>
        ) : (
          <InfoCard title="No Data">
            <Typography>No subscriptions related to this team.</Typography>
          </InfoCard>
        )}
      </Content>
    </Page>
  );
};

export const SecurityEntityPage = () => {
  const { value: scores, loading, error } = useSecureScoresHistory();
  if (loading) return <Progress />;
  if (error) return <ResponseErrorPanel error={error} />;

  return <SecurityPage scores={scores} />;
};

export const SecurityManagePage = () => {
  const useOwnedEntity = useOwnedEntities('resource');

  const discoveryApi = useApi(discoveryApiRef);
  const fetch = useApi(fetchApiRef);

  const subscriptions = useOwnedEntity
    .filter(entity => entity.spec?.type === 'azure-subscription')
    .map(entity => stringifyEntityRef(entity));

  const {
    value: scores,
    loading,
    error,
  } = useAsync(async () => {
    return fetchHistoricalScores(subscriptions, { fetch, discoveryApi });
  }, [subscriptions.join(',')]);

  if (loading) return <Progress />;
  if (error) return <ResponseErrorPanel error={error} />;

  return <SecurityPage scores={scores} />;
};
