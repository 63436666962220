import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Entity,
  RELATION_PARENT_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  CatalogApi,
  catalogApiRef,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';
import {
  fetchCurrentScores,
  fetchHistoricalScores,
} from '../api/secureScoresApi';
import limiterFactory from 'p-limit';
import { uniq } from 'lodash';

const limiter = limiterFactory(5);

/**
 * Recursively fetches all child ownership entities for a given entity
 * @param entity
 * @param catalogApi
 * @see stolen from https://github.com/backstage/backstage/blob/master/plugins/org/src/components/Cards/OwnershipCard/useGetEntities.ts#L81
 */
const getChildOwnershipEntityRefs = async (
  entity: Entity,
  catalogApi: CatalogApi,
  alreadyRetrievedParentRefs: string[] = [],
): Promise<string[]> => {
  const childGroups = getEntityRelations(entity, RELATION_PARENT_OF, {
    kind: 'Group',
  });

  const hasChildGroups = childGroups.length > 0;

  const entityRef = stringifyEntityRef(entity);
  if (hasChildGroups) {
    const entityRefs = childGroups.map(r => stringifyEntityRef(r));
    const childGroupResponse = await limiter(() =>
      catalogApi.getEntitiesByRefs({
        fields: ['kind', 'metadata.namespace', 'metadata.name', 'relations'],
        entityRefs,
      }),
    );
    const childGroupEntities = childGroupResponse.items.filter(isEntity);

    const unknownChildren = childGroupEntities.filter(
      childGroupEntity =>
        !alreadyRetrievedParentRefs.includes(
          stringifyEntityRef(childGroupEntity),
        ),
    );
    const childrenRefs = (
      await Promise.all(
        unknownChildren.map(childGroupEntity =>
          getChildOwnershipEntityRefs(childGroupEntity, catalogApi, [
            ...alreadyRetrievedParentRefs,
            entityRef,
          ]),
        ),
      )
    ).flatMap(aggregated => aggregated);

    return uniq([...childrenRefs, entityRef]);
  }

  return [entityRef];
};

/**
 * Get all owned subscription refs for a given entity and all the transitive ownership entities
 * @param entity
 * @param catalogApi
 * @returns
 */
async function getOwnedSubscriptionRefs(
  entity: Entity,
  catalogApi: CatalogApi,
) {
  const owners = await getChildOwnershipEntityRefs(entity, catalogApi);
  //TODO: see if this need to be on batch as in https://github.com/backstage/backstage/blob/master/plugins/org/src/components/Cards/OwnershipCard/useGetEntities.ts#L148
  const response = await catalogApi.getEntities({
    filter: [
      {
        kind: 'Resource',
        'relations.ownedBy': owners,
        'spec.type': 'azure-subscription',
      },
    ],
    fields: [
      'kind',
      'metadata.name',
      'metadata.namespace',
      'spec.type',
      'relations',
    ],
  });
  const ownedRefs = response.items.map(entity => stringifyEntityRef(entity));
  return ownedRefs;
}

const isEntity = (entity: Entity | undefined): entity is Entity =>
  entity !== undefined;

export const useSecureScores = () => {
  const discoveryApi = useApi(discoveryApiRef);

  const fetch = useApi(fetchApiRef);
  const catalogApi = useApi(catalogApiRef);
  const { entity } = useEntity();

  return useAsync(async () => {
    const ownedRefs = await getOwnedSubscriptionRefs(entity, catalogApi);
    return fetchCurrentScores(ownedRefs, { fetch, discoveryApi, catalogApi });
  }, [entity]);
};

export const useSecureScoresHistory = () => {
  const discoveryApi = useApi(discoveryApiRef);
  const catalogApi = useApi(catalogApiRef);
  const fetch = useApi(fetchApiRef);
  const { entity } = useEntity();

  return useAsync(async () => {
    const ownedRefs = await getOwnedSubscriptionRefs(entity, catalogApi);
    return fetchHistoricalScores(ownedRefs, { fetch, discoveryApi });
  }, [entity]);
};
