type ResourceCard = {
  title: string;
  description: string;
  linkTitle: string;
  linkUrl: string;
};

export const resourceCards: ResourceCard[] = [
  {
    title: 'Defender for Cloud TechTalk',
    description:
      'This session aims to provide a more comprehensive and in-depth understanding of how Defender for Cloud operates.',
    linkTitle: 'Watch the meeting recording',
    linkUrl:
      'https://scandinavianairlinessystem.sharepoint.com/sites/Platform/_layouts/15/stream.aspx?id=%2Fsites%2FPlatform%2FShared%20Documents%2FGeneral%2FTechTalk%2F2024%2D04%2D25%20%2D%20Defender%20for%20Cloud%2FDeveloper%20TechTalk%20%2D%20Defender%20for%20Cloud%2D20240425%5F150321%2DMeeting%20Recording%2Emp4&referrer=StreamWebApp%2EWeb&referrerScenario=AddressBarCopied%2Eview%2Ed14c33ac%2D95af%2D4e58%2D97d5%2D630c56546f24',
  },
  {
    title: 'Defender for Cloud in CDX',
    description:
      'This documentation provides guidance on adhering to security best practices concerning Defender for Cloud.',
    linkTitle: 'Read the documentation',
    linkUrl:
      'https://devportal.flysas.tech/docs/default/component/builders-library/security-practices/defender-for-cloud/overview/',
  },
];
