import { GaugePropsGetColorOptions } from '@backstage/core-components/index';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

export const useThresholds = () => {
  const config = useApi(configApiRef);

  const thresholds = config.getOptional<{
    target: number;
    critical: number;
  }>('defenderForCloudScores.thresholds') || { target: 0.75, critical: 0.5 };

  const getGaugeColor = ({ value, palette }: GaugePropsGetColorOptions) => {
    return value >= thresholds.target * 100
      ? palette.status.ok
      : value >= thresholds.critical * 100
        ? palette.status.warning
        : palette.status.error;
  };

  return { thresholds, getGaugeColor };
};
