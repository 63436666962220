import {
  Score,
  ScoreByRefResponse,
  scoreByRefResponseSchema,
} from '@internal/plugin-defender-for-cloud-scores-common';
import { DEFENDER_PLUGIN_ID } from '../constants';
import { ApiDeps } from '../types';
import {
  ANNOTATION_VIEW_URL,
  parseEntityRef,
  Entity,
  stringifyEntityRef,
} from '@backstage/catalog-model';

// Enhanced type for scores with entity info
export type EnhancedScore = Score & {
  defenderViewUrl?: string;
  entityName?: string;
  entityKind?: string;
  entityNamespace?: string;
};

// Enhanced response type
export type EnhancedScoreResponse = {
  currentScores: EnhancedScore[];
  averageScore: number | null;
};

// Type predicate to filter out undefined/null entities
function isDefinedEntity(item: Entity | undefined | null): item is Entity {
  return Boolean(item);
}
export const buildScoreUrl = async (
  discoveryApi: ApiDeps['discoveryApi'],
  namespace: string,
  kind: string,
  name: string,
  isCurrent = true,
) => {
  const baseUrl = await discoveryApi.getBaseUrl(DEFENDER_PLUGIN_ID);
  return `${baseUrl}/scores/${namespace}/${kind}/${name}${isCurrent ? '/current' : ''}`;
};
async function getSubscriptionMap(
  catalogApi: ApiDeps['catalogApi'],
  ownedRefs: string[],
) {
  // Get subscriptions
  const subscriptions = await catalogApi!.getEntitiesByRefs({
    entityRefs: ownedRefs,
    filter: [{ kind: 'Resource', 'spec.type': 'azure-subscription' }],
  });

  // Create a map of subscriptions by entityRef
  const subscriptionMap = Object.fromEntries(
    subscriptions.items.filter(isDefinedEntity).map(sub => {
      const entityRef = stringifyEntityRef({
        kind: sub.kind,
        namespace: sub.metadata?.namespace || 'default',
        name: sub.metadata.name,
      });
      return [entityRef, sub];
    }),
  );
  return subscriptionMap;
}

// Helper function for API requests with proper error handling
async function makeApiRequest<T>(
  url: string,
  options: RequestInit,
  fetch: ApiDeps['fetch'],
  validator?: (data: unknown) => T,
): Promise<T> {
  const response = await fetch.fetch(url, options);

  if (!response.ok) {
    const error = new Error(
      `Failed to fetch from ${url}: ${response.status} ${response.statusText}`,
    );
    throw error;
  }

  const data = await response.json();
  return validator ? validator(data) : (data as T);
}

export const fetchCurrentScores = async (
  ownedRefs: string[],
  { fetch, discoveryApi, catalogApi }: ApiDeps,
): Promise<EnhancedScoreResponse> => {
  try {
    // Get subscriptions
    const subscriptionMap = await getSubscriptionMap(catalogApi, ownedRefs);

    const baseUrl = await discoveryApi.getBaseUrl(DEFENDER_PLUGIN_ID);

    // Fetch scores
    const scores = await makeApiRequest<ScoreByRefResponse>(
      `${baseUrl}/scores/current/by-refs`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ entityRefs: ownedRefs }),
      },
      fetch,
      data => scoreByRefResponseSchema.parse(data),
    );

    // Enhance scores with entity information
    return {
      currentScores: scores.currentScores.map(score => ({
        ...score,
        defenderViewUrl:
          subscriptionMap[score.entityRef]?.metadata.annotations?.[
            ANNOTATION_VIEW_URL
          ],
        entityName: subscriptionMap[score.entityRef]?.metadata.name,
        entityKind: subscriptionMap[score.entityRef]?.kind,
        entityNamespace: subscriptionMap[score.entityRef]?.metadata.namespace,
      })),
      averageScore: scores.averageScore,
    };
  } catch (error) {
    console.error('Error fetching scores:', error);
    return { currentScores: [], averageScore: null };
  }
};

export const fetchHistoricalScores = async (
  ownedRefs: string[] | Entity[],
  { fetch, discoveryApi }: ApiDeps,
): Promise<Score[][]> => {
  try {
    const scoresPromises = ownedRefs.map(async resource => {
      try {
        const { kind, namespace, name } = parseEntityRef(
          typeof resource === 'string'
            ? resource
            : {
                kind: resource.kind,
                namespace: resource.metadata.namespace ?? 'default',
                name: resource.metadata.name,
              },
        );

        const url = await buildScoreUrl(
          discoveryApi,
          namespace,
          kind,
          name,
          false,
        );
        return await makeApiRequest<Score[]>(url, {}, fetch);
      } catch (error) {
        console.error(
          `Error fetching historical score for ${typeof resource === 'string' ? resource : resource.metadata.name}:`,
          error,
        );
        return null;
      }
    });

    const scores = await Promise.all(scoresPromises);
    return scores.filter((score): score is Score[] => score !== null);
  } catch (error) {
    console.error('Error fetching historical scores:', error);
    return [];
  }
};
