import React from 'react';
import {
  MANAGE_KIND_COMMON,
  ManageTabs,
} from '@backstage-community/plugin-manage';
import {
  DefenderForCloudScoresManagePage,
  SecureScoreManageCards,
  StarredSecureScoreCards,
} from '@internal/backstage-plugin-defender-for-cloud-scores';
import { InfoCard } from '@backstage/core-components';
import { Button, CardActions, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export function Manage() {
  return (
    <ManageTabs
      kinds={{
        [MANAGE_KIND_COMMON]: {
          header: <SecureScoreManageCards />,
          footer: <Footer />,
        },
      }}
      combined={{
        header: <SecureScoreManageCards />,
        footer: <Footer />,
      }}
      starred={{
        header: <StarredSecureScoreCards />,
      }}
      tabsAfter={[
        {
          path: 'DefenderForCloudScores',
          title: 'Defender For CloudScores',
          children: <DefenderForCloudScoresManagePage />,
        },
      ]}
    />
  );
}

const Footer = () => {
  return (
    <Grid item xs={12} md={12} lg={4} style={{ marginTop: '20px' }}>
      <InfoCard title={''} variant="gridItem" divider={false}>
        <Typography variant="body2">
          {'Any entities missed their flight to the catalog?'}
        </Typography>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button
            size="small"
            color="primary"
            component={Link}
            to={
              '/docs/default/component/digital-engineering-handbook/playbook/software-catalog/'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {'Learn how to add more entities'}
          </Button>
        </CardActions>
      </InfoCard>
    </Grid>
  );
};
