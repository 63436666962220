import React, { useCallback } from 'react';
import { getCompoundEntityRef, parseEntityRef } from '@backstage/catalog-model';
import { useAnalytics, useRouteRef } from '@backstage/core-plugin-api';
import {
  Direction,
  EntityNode,
  EntityRelationsGraph,
} from '@backstage/plugin-catalog-graph';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import { useNavigate } from 'react-router';

export const OrgStructure = () => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const catalogEntityRoute = useRouteRef(entityRouteRef);

  const onNodeClick = useCallback(
    (node: EntityNode) => {
      const entity = node.entity
        ? getCompoundEntityRef(node.entity)
        : parseEntityRef(node.id);

      const path = catalogEntityRoute({
        kind: entity.kind.toLocaleLowerCase('en-US'),
        namespace: entity.namespace.toLocaleLowerCase('en-US'),
        name: entity.name,
      });
      // analytics.captureEvent(
      //   'click',
      //   node.entity.metadata.title ?? humanizeEntityRef(entity),
      //   { attributes: { to: path } },
      // );
      navigate(path);
    },
    [catalogEntityRoute, navigate, analytics],
  );

  return (
    <EntityRelationsGraph
      rootEntityNames={{
        kind: 'group',
        namespace: 'default',
        name: 'digital-it-org',
      }}
      direction={Direction.LEFT_RIGHT}
      kinds={['Group']}
      curve="curveMonotoneX"
      relations={['parentOf', 'hasMember', 'memberOf']}
      mergeRelations
      renderLabel={() => ''}
      maxDepth={20}
      unidirectional
      zoom="enabled"
      onNodeClick={onNodeClick}
    />
  );
};
